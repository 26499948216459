import { ReactNode, useState } from "react";

interface ICard {
	title?: string;
	hint?: string;
	className?: string;
	onClick?: () => void;
	children?: ReactNode;
	disabled?: boolean;
}

const Card = ({
  title,
  hint,
  children,
  onClick,
  className,
  disabled,
}: ICard) => {
  return (
    <>
      {title && (
        <div className="text-tg-theme-subtitle text-xs uppercase ml-3 mb-2">
          {title}
        </div>
      )}
      <div
        className={`rounded-xl p-4 bg-tg-theme-section ${className} ${disabled ? "opacity-90 cursor-not-allowed" : "bg-opacity-70"}`}
        onClick={!disabled ? onClick : undefined}
      >
        {children}
      </div>
      <div className="text-xs font-light text-tg-theme-hint ml-3 py-1">
        {hint}
      </div>
    </>
  );
};

export default Card;
