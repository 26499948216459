import { retry } from "@reduxjs/toolkit/query/react";
import { api } from "./api";
import {CompatibilityForecast, CompatibilityGroup} from "../types/compatibility";

export type CompatibilityForecastResponse = {
	id: string,
	week: number,
	sign: number,
	compatibility: {
		general: CompatibilityResponse;
		love: CompatibilityResponse;
		work: CompatibilityResponse;
	}
};

export type CompatibilityResponse = {
	id: string;
	sign: number;
	group: CompatibilityGroup;
	percent: number;
};

function getCompatibilityFromResponse(
	src: CompatibilityForecastResponse,
): CompatibilityForecast {
	return {
		...src,
	};
}

export const compatibilityApi = api.injectEndpoints({
	endpoints: (build) => ({
		compatibilityFetch: build.query<CompatibilityForecast, void>({
			query: () => ({
				url: "v1/compatibility",
				method: "GET",
			}),
			transformResponse: (response: CompatibilityForecastResponse): CompatibilityForecast => {
				return getCompatibilityFromResponse(response);
			},
			extraOptions: {
				backoff: () => {
					retry.fail({ fake: "error" });
				},
			},
		}),
	}),
});

export const { useCompatibilityFetchQuery, useLazyCompatibilityFetchQuery } =
	compatibilityApi;

export const {
	endpoints: { compatibilityFetch },
} = compatibilityApi;
