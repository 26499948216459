import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLazyAccountFetchQuery } from "app/services/account";
import { useCreateSessionMutation } from "app/services/session";
import { setAccount, setCredentials } from "features/auth/authSlice";

import { useWebApp } from "app/telegram";
import i18n from "app/i18n";
import * as Sentry from "@sentry/react";
import Preloader from "widgets/components/preloader";

const Loader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const webApp = useWebApp();

  webApp.ready();
  webApp.expand();

  const [ createSession ] = useCreateSessionMutation();
  const [ accountFetch ] = useLazyAccountFetchQuery();

  const authorize = useCallback(
    async (data: string) => {
      try {
        const session = await createSession({
          init_data: data,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          locale:
						navigator.language.split("-")[0] ||
						Intl.DateTimeFormat().resolvedOptions().locale,
        }).unwrap();

        dispatch(setCredentials(session));

        const account = await accountFetch({}).unwrap();
        dispatch(setAccount(account));
        i18n.changeLanguage(account.settings.language || "en");
      } catch (err) {
        console.error("Failed to create session", err);
        Sentry.captureMessage("Failed to create session");
        Sentry.captureException(err);
      }
    },
    [ dispatch, navigate, createSession, accountFetch ],
  );

  useEffect(() => {
    if (webApp.initData || process.env.REACT_APP_ENV === "mocks") {
      authorize(webApp.initData)
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          console.error("Unhandled authorization error:", error);
        });
    }
  }, [ authorize, webApp.initData ]);

  return <Preloader />;
};

export default Loader;
