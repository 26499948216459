import React, { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useAccount } from "../hooks/useAccount"

import routes from "../routes";
import { useTelegram } from "../context/telegram.provider";

const Main: FC = () => {

  const auth = useAccount()
  const location = useLocation()
  const { isMock, getMainButtonText, getMainButtonClickHandler, getMainButtonVisible } = useTelegram()

  if (!auth.session) return <Navigate to="/loading" state={{ from: location }} />

  return (
    <>
      <Routes>
        {routes.map(
          ({ layout, pages }) =>
            layout === "protected" &&
            pages.map(({ path, element }) => (
              <Route path={path} element={element} />
            ))
        )}
      </Routes>
      {isMock() &&
        <footer>
          {getMainButtonVisible() &&
            <button className="w-full absolute bottom-0 h-10"
              onClick={getMainButtonClickHandler()}>
              {getMainButtonText()}
            </button>
          }
        </footer>
      }
    </>
  )
}

export default Main;
