import React from "react";

const Preloader = () => {
  return (
    <div
      className="flex h-[calc(100dvh)] justify-center items-center max-w-72 m-auto">
      <div className="loader-container">

      </div>
    </div>
  )
}

export default Preloader;
