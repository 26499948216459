import {ReactNode, useState} from "react";
import {Efficiency} from "../../app/types/efficiency";
import {useTranslation} from "react-i18next";
import {useEfficientFetchQuery} from "../../app/services/efficiency";
import {HoroscopeForecast} from "../../app/types/horoscope";
import {useHoroscopeFetchQuery} from "../../app/services/horoscope";
import Card from "../../widgets/components/card";

interface IHoroscope {
  title?: string;
  hint?: string;
  horoscope: HoroscopeForecast;
  onClick?: () => void;
  disabled?: boolean;
}

export const HoroscopeForecastFeature = () => {
  const {
    data: horoscopeForecast,
    isLoading,
    isError,
    error,
  } = useHoroscopeFetchQuery();
  const {t} = useTranslation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div/>;
  }

  return (
    <>
      { !!horoscopeForecast?.content && <Card title={t("features.horoscope.title")}>
          <p>
            {horoscopeForecast.content}
          </p>
        </Card>
      }
    </>
  );
};

export default {HoroscopeForecastFeature};
