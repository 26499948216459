import React, { FC, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { AccountAvatarForm } from "../../account/forms/accountAvatarForm";
import AccountPersonalInfoForm from "../../account/forms/accountPersonalInfoForm";
import { useAccount } from "hooks/useAccount";
import { useAccountUpdateMutation } from "app/services/account";
import { useTelegram } from "../../../context/telegram.provider";
import { useNavigate } from "react-router-dom";
import { FeatureWelcomePath } from "../routes";
import { ScreenSettingsPath } from "./screenSettings";
import { ScreenBirthdayPath } from "./screenBirthday";

export interface AccountProfile {
  first_name: string
  last_name: string
}

export const ScreenProfilePath = "profile";
const ScreenProfile = () => {

  const { t } = useTranslation()
  const account = useAccount().account
  const navigate = useNavigate()
  const [ formData, setFormData ] = useState<AccountProfile>(account)

  const handleChange = (name: any, data: any) => {
    const info = { ...formData, [name]: data }
    setFormData(info)
  }


  const { setMainButtonVisible, setMainButtonProps } = useTelegram()

  const submit = () => {
    console.log("go to birthday");
    return navigate(FeatureWelcomePath(ScreenBirthdayPath));
  }

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenProfile.mainButton"), submit);
  }, [ setMainButtonProps ]);


  const [ updateAccountRequest, { isLoading: isAccountUpdateLoading, isError: isAccountUpdateError } ] = useAccountUpdateMutation();
  const updateAccount = async () => {
    // try {
    //   const data = { ...auth.account, ...accountInfo }
    //   const result = await updateAccountRequest(data as AccountUpdateRequest).unwrap()
    //   if (!isAccountUpdateError && result) {
    //     setAccountInfo(result)
    //   }
    // } catch (err) {
    //   console.error('Failed to update account', err);
    //   Sentry.captureMessage("Failed to update account");
    //   Sentry.captureException(err);
    // }
  };


  return (
    <div className="flex flex-col min-h-screen px-5">
      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.screens.screenProfile.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.screens.screenProfile.desc")}
      </div>

      <div className="flex-1">
        <div className="mb-8">
          <AccountAvatarForm name={account.first_name || account.last_name || account.username} src={account.avatar} />
        </div>

        <div className="mb-5">
          <AccountPersonalInfoForm data={formData} onChange={handleChange} />
        </div>

        <div className="text-center pt-5 text-tg-theme-subtitle">
          {t("features.welcome.screens.screenProfile.info")}
        </div>

      </div>

    </div>
  )
}

export default ScreenProfile
