import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react"
import { selectSessionToken } from "features/auth/authSlice"
import { BaseQueryApi, FetchBaseQueryError } from "@reduxjs/toolkit/query";

const baseUrl = process.env.REACT_APP_ENV !== "mocks"
  ? window._env_?.API_HOST
  : window.location.origin;

console.log(window._env_, baseUrl)

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = selectSessionToken(getState())
    if (token) {
      headers.set("Authorization", `Bearer ${token}`)
    }
    return headers
  },
})

interface CustomExtraArgs {
  attempt: number;
  baseQueryApi: BaseQueryApi;
  extraOptions: {}; // custom options
}

type CustomRetryConditionFunction = (
  error: FetchBaseQueryError,
  args: any,
  extraArgs: CustomExtraArgs
) => boolean;

const retryCondition = (error: unknown, _: CustomRetryConditionFunction, extraArgs: CustomExtraArgs) => {
  if (error && typeof error === "object" && "status" in error) {
    const fetchError = error as FetchBaseQueryError;
    if (fetchError.status === "PARSING_ERROR") {
      return false
    }

    const status = fetchError.status as number;
    return extraArgs.attempt <= 5 && (status < 400 || status >= 500)
  }

  return false;
}

const baseQueryWithRetry = retry(
  baseQuery,
  {
    retryCondition: retryCondition,
    maxRetries: undefined,
  }
)

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: "tgminiapps",
  /**
   * A bare-bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,

  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    "Account",
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
})
