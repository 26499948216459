import React, { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface ImageUploadProps {
  name: string;
  accept: string;
  maxSize?: number; // Максимальный размер файла в байтах
  isLoading: boolean;
  onUpload: (formData: FormData, onMessage: (message: {
    state: "failed" | "success";
    message: string
  }) => void, onProgress: (progress: number) => void) => void;
  onMessage?: (message: { state: "failed" | "success"; message: string }) => void;
}

const ImageUploader: React.FC<ImageUploadProps> = ({ name, accept, maxSize, isLoading, onUpload, onMessage }) => {
  const [ isUploading, setIsUploading ] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!!maxSize && file.size > maxSize) {
      onMessage && onMessage({
        state: "failed",
        message: t("widgets.forms.uploader.uploader.fileSizeExceedsLimit", { maxSize: (maxSize / 1024 / 1024).toFixed(2) })
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setIsUploading(true);
    onUpload(formData, (message) => {
      setIsUploading(false);
      onMessage && onMessage(message);
    }, (progress) => {
    });
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="flex flex-col items-center">
      <input
        ref={fileInputRef}
        hidden
        type="file"
        accept={accept}
        onChange={handleFileChange}
        disabled={isUploading || isLoading}
      />
      <button
        className="py-1 px-10 rounded-md text-xs"
        onClick={handleButtonClick}
        disabled={isUploading || isLoading}
      >
        {isUploading || isLoading ? t("widgets.forms.uploader.uploader.uploadingMessage") : name}
      </button>
    </div>
  );
};

export default ImageUploader;
