import { ReactNode } from "react";
import { HomePage, SettingsPage } from "./pages";
import FeatureWelcome from "features/welcome/routes";

export type RouteGroup = {
	layout: string;
	pages: Route[];
};

export type Route = {
	path: string;
	element: ReactNode;
};

export const routes: RouteGroup[] = [
  {
    layout: "protected",
    pages: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/settings",
        element: <SettingsPage />,
      },
      {
        path: "/welcome/*",
        element: <FeatureWelcome />,
      },
    ],
  },
];

export default routes;
