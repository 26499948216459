import {ReactNode, useState} from "react";
import {Efficiency} from "../../app/types/efficiency";
import {useTranslation} from "react-i18next";
import {useEfficientFetchQuery} from "../../app/services/efficiency";

interface IEfficient {
  title?: string;
  hint?: string;
  efficiency: Efficiency;
  onClick?: () => void;
  disabled?: boolean;
}

export const EfficiencyListFeature = () => {
  const {
    data: efficiencyForecast,
    isLoading,
    isError,
    error,
  } = useEfficientFetchQuery();
  const {t} = useTranslation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div/>;
  }

  return (
    <>
      <div className="text-tg-theme-subtitle text-xs uppercase ml-3 mb-2">
        {t("features.efficiency.title")}
      </div>

      <div className="space-y-4">
        {efficiencyForecast && efficiencyForecast.efficiency?.general && (
          <EfficiencyCard
            key={efficiencyForecast.efficiency.general.id}
            efficiency={efficiencyForecast.efficiency.general}
            disabled={false}
          />
        )
        }
        {efficiencyForecast && efficiencyForecast.efficiency?.health && (
          <EfficiencyCard
            key={efficiencyForecast.efficiency.health.id}
            efficiency={efficiencyForecast.efficiency.health}
            disabled={false}
          />
        )
        }
        {efficiencyForecast && efficiencyForecast.efficiency?.love && (
          <EfficiencyCard
            key={efficiencyForecast.efficiency.love.id}
            efficiency={efficiencyForecast.efficiency.love}
            disabled={false}
          />
        )
        }
        {efficiencyForecast && efficiencyForecast.efficiency?.work && (
          <EfficiencyCard
            key={efficiencyForecast.efficiency.work.id}
            efficiency={efficiencyForecast.efficiency.work}
            disabled={false}
          />
        )
        }


      </div>
    </>
  );
};

export const EfficiencyCard = ({
                                title,
                                hint,
                                onClick,
                                efficiency,
                                disabled,
                              }: IEfficient) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false); // Состояние для контроля видимости

  const toggleContent = () => {
    setIsOpen(!isOpen); // Переключение состояния видимости
  };

  return (
    <>
      {title && (
        <div className="text-tg-theme-subtitle text-xs uppercase ml-3 mb-2">
          {title}
        </div>
      )}

      <div
        className={`rounded-xl p-4 bg-tg-theme-section ${disabled ? "opacity-90 cursor-not-allowed" : "bg-opacity-70"}`}
        onClick={!disabled ? onClick : undefined}
      >
        <div className="flex items-center justify-between mb-3">
          <h4 className="uppercase text-sm">
            {t(`features.efficiency.group.${efficiency.group}.title`)}
          </h4>
        </div>

        <div className="flex justify-between items-center text-gray-400 my-3">
          <p>{efficiency.summary}</p>
          <i
            className={`text-gray-400 cursor-pointer fa ${isOpen ? "fa-chevron-up" : "fa-chevron-right"}`}
            onClick={toggleContent}
          />
        </div>

        <div className="w-full bg-gray-900 rounded-full h-1.5 mb-5 dark:bg-gray-400">
          <div
            className="bg-cyan-600 h-1.5 rounded-full dark:bg-cyan-500"
            style={{width: efficiency.percent + "%"}}
          ></div>
        </div>

        {isOpen && <p className="text-gray-400 text-sm">{efficiency.content}</p>}
      </div>

      {hint && (
        <div className="text-xs font-light text-tg-theme-hint ml-3 py-1">
          {hint}
        </div>
      )}
    </>
  );
};

export default {EfficientListFeature: EfficiencyListFeature, EfficientCard: EfficiencyCard};
