import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../../hooks/useAccount";
import { useAccountSettingsUpdateMutation } from "../../../app/services/account";
import { useTelegram } from "../../../context/telegram.provider";
import { useNavigate } from "react-router-dom";
import { FeatureWelcomePath } from "../routes";
import { ScreenDonePath } from "./screenDone";

export interface AccountSettings {
	language: string;
	timezone: string;
	report_weekly_time: string;
	report_weekly_wday: number;
}

export const ScreenSettingsPath = "settings";
const ScreenSettings = () => {
  const { t } = useTranslation();
  const auth = useAccount();
  const navigate = useNavigate();

  const [ formData, setFormData ] = useState<AccountSettings>(
    auth.account.settings,
  );

  const { setMainButtonVisible, setMainButtonProps } = useTelegram();

  const submit = () => {
    console.log("go to done");
    return navigate(FeatureWelcomePath(ScreenDonePath));
  };

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(
      t("features.welcome.screens.screenSettings.mainButton"),
      submit,
    );
  }, [ setMainButtonProps ]);

  const [
    updateAccountSettingsRequest,
    {
      isLoading: isAccountSettingsUpdateLoading,
      isError: isAccountSettingsUpdateError,
    },
  ] = useAccountSettingsUpdateMutation();
  const updateSettings = async () => {
    // try {
    //   const data = { ...auth.account.settings, ...accountInfo }
    //   const result = await updateAccountSettingsRequest(data as AccountSettingsUpdateRequest).unwrap()
    //   if (!isAccountSettingsUpdateError && result) {
    //     setAccountSettings(result)
    //   }
    // } catch (err) {
    //   console.error('Failed to update account', err);
    //   Sentry.captureMessage("Failed to update account");
    //   Sentry.captureException(err);
    // }
  };

  const handleChange = (name: any, data: any) => {
    const info = { ...formData, [name]: data };
    setFormData(info);
    submit();
  };

  return (
    <div className="flex flex-col min-h-screen px-5">
      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.screens.screenSettings.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.screens.screenSettings.desc")}
      </div>

      <div className="flex-1">
        <div className="text-center pt-5 text-tg-theme-subtitle">
          {t("features.welcome.screens.screenSettings.info")}
        </div>
      </div>
    </div>
  );
};

export default ScreenSettings;
