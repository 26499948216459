import { useTranslation } from "react-i18next";
import Card from "../../../widgets/components/card";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useTelegram } from "context/telegram.provider";

export const ScreenDonePath = "done";
const ScreenDone = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { setMainButtonVisible, setMainButtonProps } = useTelegram()

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenSettings.mainButton"), submit);
  }, [ setMainButtonProps ]);


  const submit = () => {
    console.log("go to home page");
    return navigate("/");
  }

  return (
    <div className="flex flex-col min-h-screen px-5">

      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.screens.screenDone.title")}
      </h1>

      <div className="text-center mb-5">
        {t("features.welcome.screens.screenDone.desc")}
      </div>

      <div className="flex-1 mt-6">
        {Array.from({ length: 3 }, (_, i) => {
          const icons = [ "fa fa-user-clock", "fa-solid fa-user-tie", "fa fa-calendar-days" ]
          return (
            <Card key={i} title={t("features.welcome.screens.screenDone.features." + i + ".title")} className="mb-5">
              <div className="flex justify-center items-center min-h-22">
                <div className="min-w-10 text-center">
                  <i className={`${icons[i]}`} />
                </div>
                <div className="flex-auto px-4 text-sm">
                  {t("features.welcome.screens.screenDone.features." + i + ".desc")}
                </div>
              </div>
            </Card>
          )
        })}
      </div>
    </div>
  )
}

export default ScreenDone
