import {useCompatibilityFetchQuery} from "../../app/services/compatibility";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Compatibility} from "../../app/types/compatibility";
import {getSign} from "../../app/types/sign";

interface ICompatibility {
  compatibility: Compatibility;
  onClick?: () => void;
  disabled?: boolean;
}

export const CompatibilityListFeature = () => {
  const {
    data: compatibilityForecast,
    isLoading,
    isError,
    error,
  } = useCompatibilityFetchQuery();
  const {t} = useTranslation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div/>;
  }

  return (
    <>
      <div className="text-tg-theme-subtitle text-xs uppercase ml-3 mb-2">
        {t("features.compatibility.title")}
      </div>

      <div className="space-x-4 flex flex-col-3">
        {compatibilityForecast && compatibilityForecast.compatibility?.general && (
          <CompatibilityCard
            key={compatibilityForecast.compatibility.general.id}
            compatibility={compatibilityForecast.compatibility.general}
            disabled={false}
          />
        )
        }
        {compatibilityForecast && compatibilityForecast.compatibility?.love && (
          <CompatibilityCard
            key={compatibilityForecast.compatibility.love.id}
            compatibility={compatibilityForecast.compatibility.love}
            disabled={false}
          />
        )
        }
        {compatibilityForecast && compatibilityForecast.compatibility?.work && (
          <CompatibilityCard
            key={compatibilityForecast.compatibility.work.id}
            compatibility={compatibilityForecast.compatibility.work}
            disabled={false}
          />
        )
        }


      </div>
    </>
  );
};

export const CompatibilityCard = ({
                                onClick,
                                compatibility,
                                disabled,
                              }: ICompatibility) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false); // Состояние для контроля видимости

  const toggleContent = () => {
    setIsOpen(!isOpen); // Переключение состояния видимости
  };

  return (
    <>
      <div
        className={`rounded-xl basis-1/3 p-4 bg-tg-theme-section ${disabled ? "opacity-90 cursor-not-allowed" : "bg-opacity-70"}`}
        onClick={!disabled ? onClick : undefined}
      >


        <div className="flex items-center justify-between mb-3">
          <h4 className="uppercase text-sm text-center">
            {t(`features.compatibility.group.${compatibility.group}.title`)}
          </h4>
        </div>

        <div className="w-full  my-5 text-center content-center h-[100px]">
          <img
            src={`/img/constellation/${getSign(compatibility.sign)}.svg`}
            className="h-20 w-20 mx-auto rounded-full bg-tg-theme-section"
            alt={getSign(compatibility.sign)}
          />
        </div>


        <div className="flex items-center justify-between mb-3">
          <h4 className="uppercase text-sm">
            {getSign(compatibility.sign)}
          </h4>
        </div>

      </div>
    </>
  );
};

export default {CompatibilityListFeature, CompatibilityCard};
