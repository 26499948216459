import { useMemo } from "react"
import { useSelector } from "react-redux"
import { selectCurrentAccount, selectSessionToken } from "features/auth/authSlice"
import { Account } from "app/types/account"
import { Session } from "app/types/session"

export const useAccount = () => {
  const session: Session = useSelector(selectSessionToken)
  const account: Account = useSelector(selectCurrentAccount)
  return useMemo(() => ({ account: account, session: session }), [ account, session ])
}
