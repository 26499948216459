import { useEffect, useState } from "react";
import { useTelegram } from "../context/telegram.provider";
import { useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import AccountDetailsForm from "../features/account/forms/accountDetailsForm";
import { AccountAvatarForm } from "../features/account/forms/accountAvatarForm";
import AccountPersonalInfoForm from "../features/account/forms/accountPersonalInfoForm";

type FormDataSettings = {
	language: string;
	timezone: string;
	report_weekly_time: string;
	report_weekly_wday: number;
};

type FormDataBirthday = {
	date: number;
	country: string;
	city: string;
	street: string;
	state: string;
	latitude: number;
	longitude: number;
};

export const SettingsPage = () => {
  const navigate = useNavigate();
  const auth = useAccount();
  const { screen, setBackButtonVisible, setBackButtonOnClick, setScreen } =
		useTelegram();

  const [ formDataSettings, setFormDataSettings ] = useState<FormDataSettings>(
    auth.account.settings,
  );
  const [ formDataBirthday, setFormDataBirthday ] = useState<FormDataBirthday>(
    auth.account.birthday,
  );

  const goToHome = () => {
    navigate("/");
  };

  const handleChangeSettings = (name: any, data: any) => {
    setFormDataSettings({ ...formDataSettings, [name]: data });
    setScreen(undefined);
  };

  const handleChangeBirthday = (name: any, data: any) => {
    setFormDataBirthday({ ...formDataBirthday, [name]: data });
    setScreen(undefined);
  };

  // Configure navigation routing
  useEffect(() => {
    if (!!screen) return;
    setBackButtonVisible(true);
    setBackButtonOnClick(goToHome);
  }, [ screen, formDataSettings, formDataBirthday ]);

  return (
    <div>
      {/*<button onClick={handlePaymentCreate}>PAYMENT</button>*/}

      <div className="my-5 px-5">
        <AccountAvatarForm
          name={auth.account.first_name}
          src={auth.account.avatar}
        />
      </div>

      <div className="mb-5 px-5">
        <AccountPersonalInfoForm
          data={formDataSettings}
          onChange={handleChangeSettings}
        />
      </div>

      <div className="mb-5 px-5">
        <AccountDetailsForm
          data={formDataBirthday}
          onChange={handleChangeBirthday}
        />
      </div>
    </div>
  );
};