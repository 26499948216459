import { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { useAccount } from "../../hooks/useAccount"
import ScreenHello, { ScreenHelloPath } from "./screens/screenHello";
import ScreenProfile, { ScreenProfilePath } from "./screens/screenProfile";
import { RouteGroup } from "routes";
import ScreenSettings, { ScreenSettingsPath } from "./screens/screenSettings";
import ScreenBirthday, { ScreenBirthdayPath } from "./screens/screenBirthday";
import ScreenDone, { ScreenDonePath } from "./screens/screenDone";

const FeaturePrefix = "welcome"

export const FeatureWelcomePath = (screen: string) => {
  return "/" + [ FeaturePrefix, screen ].join("/")
}


const FeatureWelcome: FC = () => {

  const auth = useAccount()
  const location = useLocation()

  if (!auth.session) return <Navigate to="/loading" state={{ from: location }} />



  const routes: RouteGroup[] = [
    {
      layout: FeaturePrefix,
      pages: [
        {
          path: ScreenHelloPath,
          element: <ScreenHello />,
        },
        {
          path: ScreenProfilePath,
          element: <ScreenProfile />,
        },
        {
          path: ScreenBirthdayPath,
          element: <ScreenBirthday />
        },
        {
          path: ScreenSettingsPath,
          element: <ScreenSettings />,
        },
        {
          path: ScreenDonePath,
          element: <ScreenDone />
        },
      ]
    }
  ];

  return (
    <Routes>
      {routes.map(
        ({ layout, pages }) =>
          layout === FeaturePrefix &&
          pages.map(({ path, element }) => (
            <Route path={path} element={element} />
          ))
      )}
    </Routes>
  )
}

export default FeatureWelcome;
