import { retry } from "@reduxjs/toolkit/query/react";
import { api } from "./api";
import {
	Efficiency,
	EfficiencyForecast,
	EfficiencyGroup,
} from "../types/efficiency";

export type EfficiencyForecastResponse = {
	id: string,
	week: number,
	sign: number,
	efficiency: {
		general: EfficiencyResponse;
		health: EfficiencyResponse;
		love: EfficiencyResponse;
		work: EfficiencyResponse;
	}
};

export type EfficiencyResponse = {
	id: string;
	sign: number;
	group: EfficiencyGroup;
	percent: number;
	summary: string;
	content: string;
};

function getEfficientFromResponse(
	src: EfficiencyForecastResponse,
): EfficiencyForecast {
	return {
		...src,
	};
}

export const efficientApi = api.injectEndpoints({
	endpoints: (build) => ({
		efficientFetch: build.query<EfficiencyForecast, void>({
			query: () => ({
				url: "v1/efficiency",
				method: "GET",
			}),
			transformResponse: (response: EfficiencyForecastResponse): EfficiencyForecast => {
				return getEfficientFromResponse(response);
			},
			extraOptions: {
				backoff: () => {
					retry.fail({ fake: "error" });
				},
			},
		}),
	}),
});

export const { useEfficientFetchQuery, useLazyEfficientFetchQuery } =
	efficientApi;

export const {
	endpoints: { efficientFetch },
} = efficientApi;
