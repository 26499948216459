import { getCDNURL } from "app/utils";
import React, { FC } from "react";

export interface Props {
  src: string
  name: string
  rounded?: boolean
  size?: number
}

const Avatar: FC<Props> = ({ name, src, size = 15, rounded = false }) => {

  const generateColorFromChar = (char: string): string => {
    const codePoint = char?.codePointAt(0) || 0;
    const hue = (codePoint * 137) % 360; // Spread hues more evenly
    const saturation = 60 + (codePoint % 20); // Vary saturation between 60% and 80%
    const lightness = 30 + ((codePoint * 73) % 20); // Vary lightness between 30% and 50%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const darkenHSL = (hsl: string, amount: number): string => {
    const [ h, s, l ] = hsl.match(/\d+/g)!.map(Number);
    const newLightness = Math.max(0, l - amount); // Ensure lightness doesn't go below 0
    return `hsl(${h}, ${s}%, ${newLightness}%)`;
  };

  const getInitials = (name: string): string => {
    const matches = name.match(/(^\S\S?|\s\S)?/g);
    if (!matches) return "";

    const trimmedMatches = matches.map(v => v.trim());
    const joinedMatches = trimmedMatches.join("").match(/(^\S|\S$)?/g);

    return joinedMatches ? joinedMatches.join("").toLocaleUpperCase() : "";
  }

  const backgroundColor = generateColorFromChar(name[0]);
  const borderColor = darkenHSL(backgroundColor, 10);

  if (!src) {
    return (
      <div
        className={`flex items-center justify-center border-2 border-tg-theme-background-secondary ${rounded && "rounded-full"}`}
        style={{
          backgroundColor,
          border: `2px solid ${borderColor}`,
          height: size + "rem",
          width: size + "rem"
        }}
      >
        <span className="text-center text-sm text-white font-bold">
          {getInitials(name)}
        </span>
      </div>
    )
  }

  return (
    <div>
      <img
        className={`${rounded && "rounded-full"} border-2 object-cover object-center border-gray-200 dark:border-gray-700`}
        style={{
          backgroundColor,
          border: `2px solid ${borderColor}`,
          height: size + "rem",
          width: size + "rem"
        }}
        src={getCDNURL(src)}
        alt="avatar"
      />
    </div>
  )
}

export default Avatar
