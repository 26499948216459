import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { useAccount } from "../../../hooks/useAccount";
import AccountBirthdayForm from "../../account/forms/accountSignForm";
import { useAccountBirthdayUpdateMutation } from "../../../app/services/account";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../../context/telegram.provider";
import { FeatureWelcomePath } from "../routes";
import { ScreenSettingsPath } from "./screenSettings";

export interface AccountBirthday {
  date: number,
  country: string,
  city: string,
  street: string,
  state: string,
  latitude: number,
  longitude: number,
}

export const ScreenBirthdayPath = "birthday";
const ScreenBirthday = () => {

  const { t } = useTranslation()
  const auth = useAccount()
  const navigate = useNavigate()


  const { setMainButtonVisible, setMainButtonProps } = useTelegram()

  const submit = () => {
    console.log("go to settings");
    return navigate(FeatureWelcomePath(ScreenSettingsPath));
  }

  useEffect(() => {
    setMainButtonVisible(true);
    setMainButtonProps(t("features.welcome.screens.screenBirthday.mainButton"), submit);
  }, [ setMainButtonProps ]);

  const [ formData, setFormData ] = useState<AccountBirthday>(auth.account.birthday)
  const [ updateAccountBirthdayRequest, { isLoading: isAccountBirthdayUpdateLoading, isError: isAccountBirthdayUpdateError } ] = useAccountBirthdayUpdateMutation();
  const updateBirthday = async () => {
    // try {
    //   const data = { ...auth.account.birthday, ...accountInfo }
    //   const result = await updateAccountBirthdayRequest(data as AccountBirthdayUpdateRequest).unwrap()
    //   if (!isAccountBirthdayUpdateError && result) {
    //     setAccountBirthday(result)
    //   }
    // } catch (err) {
    //   console.error('Failed to update account', err);
    //   Sentry.captureMessage("Failed to update account");
    //   Sentry.captureException(err);
    // }
  };

  const handleChange = (name: any, data: any) => {
    const info = { ...formData, [name]: data }
    setFormData(info)
  }

  return (
    <div className="flex flex-col min-h-screen px-5">
      <h1 className="text-3xl font-bold pt-8 text-center">
        {t("features.welcome.screens.screenBirthday.title")}
      </h1>

      <div className="mb-5 text-center">
        {t("features.welcome.screens.screenBirthday.desc")}
      </div>

      <div className="flex-1">

        <div className="mb-5">
          <AccountBirthdayForm data={formData} onChange={handleChange} />
        </div>

        <div className="text-center pt-5 text-tg-theme-subtitle">
          {t("features.welcome.screens.screenBirthday.info")}
        </div>

      </div>

    </div>
  )
}

export default ScreenBirthday
