import { retry } from "@reduxjs/toolkit/query/react";
import { api } from "./api";
import {
	Efficiency,
	EfficiencyForecast,
	EfficiencyGroup,
} from "../types/efficiency";
import {HoroscopeForecast} from "../types/horoscope";

export type HoroscopeForecastResponse = {
	id: string;
	week: number;
	sign: number;
	day: number;
	summary: string;
	content: string;
};


function getHoroscopeFromResponse(
	src: HoroscopeForecastResponse,
): HoroscopeForecast {
	return {
		...src,
	};
}

export const horoscopeApi = api.injectEndpoints({
	endpoints: (build) => ({
		horoscopeFetch: build.query<HoroscopeForecast, void>({
			query: () => ({
				url: "v1/horoscope",
				method: "GET",
			}),
			transformResponse: (response: HoroscopeForecastResponse): HoroscopeForecast => {
				return getHoroscopeFromResponse(response);
			},
			extraOptions: {
				backoff: () => {
					retry.fail({ fake: "error" });
				},
			},
		}),
	}),
});

export const { useHoroscopeFetchQuery, useLazyHoroscopeFetchQuery } =
	horoscopeApi;

export const {
	endpoints: { horoscopeFetch },
} = horoscopeApi;
